import { Box, Container, Grid, Link } from '@mui/material';
import React ,{useState} from 'react'
import Regtxt from '../../Regtxt';
import Paragraph from '../../Paragraph';
import LockerMenu from '../../LockerMenu';
import BlueButton from '../../BlueButton'; 
import BlueButtonfirst from '../../BlueButtonfirst';
import styled from '@emotion/styled'; 
import heroBg from '../../../../assets/Images/heroBg.png'; // Import your video file
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import x from "../../../../assets/Images/x.png";
import tg from "../../../../assets/Images/tg.png";
import medium from "../../../../assets/Images/Medium.png";
import AvatarLogo from '../../HoverLogo';
import { Height } from '@mui/icons-material';

export const MainIconImg2 = styled.img`
  width: ${props=>props.w? props.w :"160px"};
  height: auto;
`;
const BgImage = styled.img`
  width:100%;
  min-height:100vh;
  position:absolute;
  right:0;
  left: 0;
  object-fit: cover;
  object-position: top;
  z-index:-1;
  @media (max-width:700px) {
    bottom:133px;
    display:none;
  }

`;

const HeaderDesign = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (max-width: 900px) {
    min-height: 100vh;
  }

  /* Add hover effect styling if needed */
`;

const HoverText = styled.div`
  position: absolute;
  left: 100%;
  font-size: 16px;
  color: white;
  display: ${props => (props.show ? 'block' : 'none')}; /* Conditional rendering of the text */
`;

export const WhiteLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
const Home = () => { 
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [showText3, setShowText3] = useState(false);
  const [showText4, setShowText4] = useState(false);



  return (  
    <>


    <HeaderDesign id="home">
      <Box style={{position: 'absolute', left: 0, right: 0, zIndex: 0, minHeight: '100vh'}} >
        <BgImage src={heroBg} alt=""/>
        <AvatarLogo />
      </Box>
      <LockerMenu />
      <Container maxWidth="xl"  >
        <Grid container sx={{ minHeight:{xs:"calc(110vh)",sm:"calc(100vh)"},padding:"100px 0 40px" ,display:"flex", flexDirection:"row", justifyContent:{xs:"end",md:"space-between"},alignItems:{xs:"start",md:"end"}}}>
          <Grid item xs={12} md={6} data-aos="fade-right">

              <Regtxt fs599="33px" my="0px">Rent, Relax, Repeat.</Regtxt>
              <Box display="flex" flexDirection="column"  justifyContent="center" alignItems="start" gap="10px">  
                <Paragraph variant="h5" padding="10px 0"  sx={{maxWidth:"570px"}}>Effortless Property <br/>
                Management!</Paragraph>  
                <Paragraph padding="5px 0 20px"  sx={{maxWidth:"500px"}}>At Block Inn, our mission is to revolutionize the real estate industry by seamlessly integrating blockchain technology.</Paragraph> 
                {/* <BlueButton bg="transparent" c="#fff" b="1px solid #fff" href="">Join Waitlist</BlueButton> */}
              </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} data-aos="fade-right">

              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" borderBottom="1px solid #ffffff2f" width="100%">  
                <BlueButtonfirst bg="transparent" bc="transparent"  href=""  showText={showText} onMouseEnter={() => setShowText(true)} onMouseLeave={() => setShowText(false)} >Rental Stay App &nbsp; <EastOutlinedIcon/><HoverText show={showText}>Coming Soon</HoverText></BlueButtonfirst>
              </Box>
              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" borderBottom="1px solid #ffffff2f" width="100%">  
                <BlueButtonfirst bg="transparent" bc="transparent"  href=""  showText1={showText} onMouseEnter={() => setShowText1(true)} onMouseLeave={() => setShowText1(false)}>Off-Plan Investment Options &nbsp; <EastOutlinedIcon/><HoverText show={showText1}>Coming Soon</HoverText></BlueButtonfirst>
              </Box>
              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" borderBottom="1px solid #ffffff2f" width="100%">  
                <BlueButtonfirst bg="transparent" bc="transparent"  href=""  showText2={showText} onMouseEnter={() => setShowText2(true)} onMouseLeave={() => setShowText2(false)}>B2B-Specific APIs &nbsp; <EastOutlinedIcon/><HoverText show={showText2}>Coming Soon</HoverText></BlueButtonfirst>
              </Box>
              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" borderBottom="1px solid #ffffff2f" width="100%">  
                <BlueButtonfirst bg="transparent" bc="transparent"  href=""  showText3={showText} onMouseEnter={() => setShowText3(true)} onMouseLeave={() => setShowText3(false)}>Property Management Tools &nbsp; <EastOutlinedIcon/><HoverText show={showText3}>Coming Soon</HoverText></BlueButtonfirst>
              </Box>
              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" width="100%">  
                <BlueButtonfirst bg="transparent" bc="transparent"  href=""  showText4={showText} onMouseEnter={() => setShowText4(true)} onMouseLeave={() => setShowText4(false)}>Realtor CRM Integration &nbsp; <EastOutlinedIcon/><HoverText show={showText4}>Coming Soon</HoverText></BlueButtonfirst>
              </Box>
              <Box display="flex" justifyContent="end" padding="10px 0" alignItems="center" width="100%" gap="10px">  
                  <Paragraph>Follow us</Paragraph> 
                  <WhiteLink href="https://x.com/blockinnETH" target="blank">
                    <MainIconImg2  w="35px" src={x} alt="Twitter" />
                  </WhiteLink>
                  <WhiteLink href="https://t.me/blockinnETH" target="blank">
                    <MainIconImg2  w="35px" src={tg} alt="Telegram" />
                  </WhiteLink>
                  <WhiteLink href="https://medium.com/@blockinnETH" target="blank">
                    <MainIconImg2  w="35px" src={medium} alt="Medium" />
                  </WhiteLink>
                </Box>
              {/* <Box display="flex"  justifyContent="center" alignItems="start" gap="10px" sx={{maxWidth:"500px"}}>  
                <InputField placeholder='Email Address'/>
                <BlueButton href="">Join Waitlist</BlueButton>
              </Box> */}
          </Grid>
        </Grid>
      </Container>
    </HeaderDesign>
    </>
  )
}

export default Home;