import React from 'react'
import styled  from '@emotion/styled'
import { Box, Container, Grid, Link } from '@mui/material'
import Bgimg from "../../../../assets/Images/imgbg.png" 
import unicorn from "../../../../assets/Images/unicorn.png" 
import recticon from "../../../../assets/Images/recticon.png" 
import dexScreener from "../../../../assets/Images/dexScreener.png" 
import Paragraph from '../../Paragraph'
import ParagraphContract from '../../Paragraphcontract'

import BlueButton from '../../BlueButton'

const MainIconImg2 = styled.img`
  width: ${props=>props.w? props.w :"160px"};
  height: auto;
`;

const WhiteLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
const MainHeader = styled(Container)`
  min-height: auto; 
  display:flex;  
  flex-direction: column;
  justify-content:start; 
  align-items:center; 
  gap:20px; 
  margin-top: 100px;
  border-radius: 10px;
`
const HeaderGrid = styled(Grid)`
  min-height: auto;
  background-color: #0C1420;
  background-image: url(${Bgimg});
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  border-radius: 10px;
  padding: 40px 40px 60px;
  border: 1px solid #1A2940;
  width: 100%;
  @media(max-width: 600px){
    padding-bottom: 250px;
  background-position: 80%;
  background-size: cover;
  }
` 

const tokenInfo = [
  {
      title: "Full name",
      detail: "Block Inn",
    },
    {
      title: "Symbol",
      detail: "INN",
    },
    {
      title: "Max supply",
      detail: "110,000,000",
    },
    {
      title: "TAX",
      detail: "5/5",
    },
    {
      title: "Network",
      detail: "Ethereum (ERC-20)",
    },
    {
      title: "Useful links",
      detail: <Box display="flex" justifyContent="end" alignItems="center" width="100%" gap="10px">  
      <WhiteLink href="https://etherscan.io/address/0x73B62d93928CE20392dD96Df5061c41E734BcDA5" target="blank">
        <MainIconImg2  w="45px" src={dexScreener} alt="" />
      </WhiteLink>
      <WhiteLink href="" target="blank">
        <MainIconImg2  w="45px" src={unicorn} alt="" />
      </WhiteLink>
      <WhiteLink href="https://www.dextools.io/app/en/ether/pair-explorer/0x39afcf7f98a45c5b07b3d8bb7c9ccfb831c2326a?t=1729023967800" target="blank">
        <MainIconImg2  w="45px" src={recticon} alt="" />
      </WhiteLink>
    </Box>,
    },
  ]; 
  const supplyDistribution = [
    {
        title: "Holder migration",
        detail: "TBA",
      },
      {
        title: "Liquidity pool",
        detail: "TBA",
      },
      {
        title: "Development",
        detail: "TBA",
      },
      {
        title: "Marketing",
        detail: "TBA",
      },
      {
        title: "Exchange liqudity",
        detail: "TBA",
      },
    ]; 
 const DetailCard = ({list}) =>{
  
  
  return(
    <>
    {
      list.map((field)=>(
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" >
          <Paragraph>
            {field.title}
          </Paragraph>
          <Paragraph>
            {field.detail}
          </Paragraph>
        </Box>
      ))
    }
    </>
    
  )
 }

 
const Tokenomics = () => { 

    
  return (
    <MainHeader maxWidth="xl" id="tokenomics">  
       <HeaderGrid container display="flex" justifyContent='start' alignItems="center">   
        <Grid item xs={12} md={5.9} lg={5.9} display="flex" flexDirection="column" padding="20px 0" gap="20px" data-aos="zoom-in" >    
          <Paragraph variant="h4" fontFamily="MontserratR" ></Paragraph>
          <Paragraph variant="h3" fontFamily="MontserratR" >$INN</Paragraph>
          <Paragraph variant="body1" maxWidth="550px"> 
          The $INN token powers the Block Inn ecosystem, providing access to 
          opportunities that unlock additional benefits for users and investors.          </Paragraph> 
          <Box display="flex" flexDirection={{xs:"column",sm:"row"}} gap="10px">
            <BlueButton href="https://app.uniswap.org/swap?outputCurrency=0x73B62d93928CE20392dD96Df5061c41E734BcDA5&chain=ethereum" >Buy Now</BlueButton>
            <BlueButton bg="transparent" c="#fff" b="1px solid #fff" href="https://dexscreener.com/ethereum/0x39afcf7f98a45c5b07b3d8bb7c9ccfb831c2326a" target="_blank">Dex Screener</BlueButton>
            <BlueButton bg="transparent" c="#fff" b="1px solid #fff" href="https://www.dextools.io/app/en/ether/pair-explorer/0x39afcf7f98a45c5b07b3d8bb7c9ccfb831c2326a?t=1729022821951" target="_blank">Dex Tools</BlueButton>

          </Box>
        </Grid> 
       </HeaderGrid>
        <Grid container display="flex" justifyContent="space-between" alignItems="start" gap={{xs:"20px",sm:"none"}}> 
          <Grid item xs={12} md={6} sx={{height:"100%",borderRadius:"10px",padding:"30px 20px",minHeight:"220px",background:"#0C1420",border:"1px solid #1A2940",display:"flex",flexDirection:"column",gap:"15px"}}>
            <Paragraph variant="h5">
              Token info
            </Paragraph>
            <DetailCard list={tokenInfo}/>
            
          </Grid>
          <Grid container item xs={12} md={5.7} >
            <Grid item xs={12}  sx={{height:"100%",borderRadius:"10px",padding:"55px 20px",minHeight:"150px",background:"#0C1420",border:"1px solid #1A2940",display:"flex",flexDirection:"column",gap:"15px"}}>
            <Paragraph variant="h6" >
                Token contract:
                </Paragraph>
            <Grid item xs={13}  sx={{height:"100%",borderRadius:"10px",padding:"12px 20px",marginTop:"20px",minHeight:"35px",background:"#FF4141",display:"flex",flexDirection:"column",gap:"15px"}}>
              <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" >
                <ParagraphContract>
                0x73b62d93928ce20392dd96df5061c41e734bcda5
                </ParagraphContract>
                
              </Box>
              
            </Grid>
            <Paragraph variant="h6">
                Old (migrated contract): 
                </Paragraph>
            <Grid item xs={12}  sx={{height:"100%",borderRadius:"10px",padding:"12px 20px",marginTop:"20px",minHeight:"35px",background:"#FF4141",display:"flex",flexDirection:"column",gap:"15px"}}>
              <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" >
                
                <ParagraphContract>
                0xf533d33c8ea3616777f0aa99f03c00bdb349aebe
                </ParagraphContract>
                
              </Box>
              
            </Grid>
            </Grid>
            
          </Grid>
        </Grid>  
    </MainHeader> 
  ) 
  }
export default Tokenomics;