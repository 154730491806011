import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import './Avatar.css'; // Assuming your CSS is adapted to work with this component
import logoImage from '../../../assets/Images/header-Logo.png'

// Configuration object for easy adjustments
const CONFIG = {
  perspective: {
    xDivisor: 26,
    yDivisor: 18,
    transitionDuration: {
      enter: '0.01s',
      leave: '0.08s'
    }
  },
  eyes: {
    movementDivisor: 100,
    transitionDuration: {
      track: '0.2s',
      reset: '1.4s'
    }
  }
};

const AvatarLogo = () => {
  // Refs to directly manipulate DOM elements
  const perspectiveRef = useRef(null);
  const eyeLeftRef = useRef(null);
  const eyeRightRef = useRef(null);

  // Calculate axis values based on mouse position
  const calculateAxisValues = (e) => {
    const { innerWidth, innerHeight } = window;
    return {
      x: (innerWidth / 2 - e.pageX),
      y: (innerHeight / 2 - e.pageY)
    };
  };

  // Handle perspective rotation on mouse movement
  const handlePerspective = (e) => {
    const perspective = perspectiveRef.current;
    const { x, y } = calculateAxisValues(e);

    const xAxis = x / CONFIG.perspective.xDivisor;
    const yAxis = y / CONFIG.perspective.yDivisor;

    if (perspective) {
      perspective.style.transform = `rotateY(${-xAxis}deg) rotateX(${yAxis}deg)`;
    }
  };

  // Handle eye movement on mouse movement
  const handleEyeMovement = (e) => {
    const eyeLeft = eyeLeftRef.current;
    const eyeRight = eyeRightRef.current;
    const { x, y } = calculateAxisValues(e);

    const moveX = -x / CONFIG.eyes.movementDivisor;
    const moveY = -y / CONFIG.eyes.movementDivisor;

    const transform = `translateY(${moveY}px) translateX(${moveX}px)`;
    const transition = `transform ${CONFIG.eyes.transitionDuration.track} ease-out`;

    if (eyeLeft && eyeRight) {
      [eyeLeft, eyeRight].forEach((eye) => {
        eye.style.transform = transform;
        eye.style.transition = transition;
      });
    }
  };

  // Handle pointer enter to reset transition
  const handlePointerEnter = () => {
    const perspective = perspectiveRef.current;
    if (perspective) {
      perspective.style.transition = CONFIG.perspective.transitionDuration.enter;
    }
  };

  // Handle pointer leave to reset to default state
  const handlePointerLeave = () => {
    const perspective = perspectiveRef.current;
    const eyeLeft = eyeLeftRef.current;
    const eyeRight = eyeRightRef.current;

    // Reset perspective
    if (perspective) {
      perspective.style.transform = 'rotateY(0deg) rotateX(0deg)';
      perspective.style.transition = CONFIG.perspective.transitionDuration.leave;
    }

    // Reset eyes
    const transition = `transform ${CONFIG.eyes.transitionDuration.reset} ease-out`;
    if (eyeLeft && eyeRight) {
      [eyeLeft, eyeRight].forEach((eye) => {
        eye.style.transform = 'translateY(0) translateX(0)';
        eye.style.transition = transition;
      });
    }
  };

  // Effect to add event listeners on mount and clean up on unmount
  useEffect(() => {
    const handleMouseMove = (e) => {
      handlePerspective(e);
      handleEyeMovement(e);
    };

    const avatarSection = document.querySelector('.avatar__section');
    if (avatarSection) {
      avatarSection.addEventListener('pointermove', handleMouseMove);
      avatarSection.addEventListener('pointerenter', handlePointerEnter);
      avatarSection.addEventListener('pointerleave', handlePointerLeave);
    }

    return () => {
      if (avatarSection) {
        avatarSection.removeEventListener('pointermove', handleMouseMove);
        avatarSection.removeEventListener('pointerenter', handlePointerEnter);
        avatarSection.removeEventListener('pointerleave', handlePointerLeave);
      }
    };
  }, []);

  return (
    <Box
      className="avatar__section"
      sx={{
        width: '100%',
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '@media (max-width: 899px)': {
            display: 'none',
        }
      }}
    >
      <Box
        className="avatar__perspective"
        ref={perspectiveRef}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          transformStyle: 'preserve-3d',
          perspective: '200vh',
          opacity: 1,
          transition: 'transform 0.4s ease-out, opacity 0.4s ease-out'
        }}
      >
        <figure className="avatar__figure">
          <picture className="avatar__shadow">
            <img
              src={logoImage}
              alt="Avatar head"
            />
          </picture>
          <picture className="avatar__hair">
            <img
              src={logoImage}
              alt="Avatar hair"
            />
          </picture>
          {/* <picture className="avatar__ears">
            <img
              src={logoImage}
              alt="Avatar ears"
            />
          </picture> */}
          {/* <picture className="avatar__head">
            <img
              src={logoImage}
              alt="Avatar head"
            />
          </picture> */}
        </figure>
      </Box>
    </Box>
  );
};

export default AvatarLogo;
