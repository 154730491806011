import React from 'react'
import {  Container, Grid } from '@mui/material'; 
import checkArrow from '../../../../assets/Images/checkArrow.png'
import uncheckArrow from '../../../../assets/Images/uncheckArrow.png' 
import roadmap from '../../../../assets/Images/roadmap.png'
import styled from '@emotion/styled';
import Regtxt from '../../Regtxt';
import Paragraph from '../../Paragraph';


const MainHeader = styled.div`
  min-height: auto; 
  display:flex;  
  flex-direction: column;
  justify-content:center; 
  background-image: url(${roadmap});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items:center; 
  gap:70px; 
  margin: 200px 0px;
  padding: 100px 0;
  @media(max-width: 599px){
    /* padding: 0 20px; */
  }
`
const Image = styled.img`
  width: 100%;
  @media(max-width: 899px){
    rotate: 270deg;
  }
`
const DetailCard = ({list}) =>{
  
  
  return(
    <>
    {
      list.map((field)=>(
          <Paragraph padding="10px 0">
            {field}
          </Paragraph>
      ))
    }
    </>
    
  )
 }
 const list1 =[
    "- Block Inn Website launch",
    "- $Brick Token migration to $Inn",
    "- $Inn token launch",
    "- Rental Stay App design preview",
      
  ]; 
  const list2 =[
    "- Launch of Rental Stay App",
    "- Integration of B2B-specific APIs",
    "- Property Management Tools rollout",
    "- Off-plan investment options introduction",
    "- Realtor CRM integration",
      
  ]; 
  const list3 =[
    "- Expansion of automated income distribution",
    "- Global scaling of platform services",
    "- Enhanced real-time property data features",
    "- Strategic partnerships for market growth",
    "- Continuous platform optimization and updates",
      
  ]; 
const Roadmap = () => { 


    
  return ( 
    <MainHeader id="roadmap"> 
    <Regtxt textAlign="center">Roadmap</Regtxt>
    <Container maxWidth="xl">

    <Grid container>
      <Grid container item xs={1} md={12} sx={{display:"flex"}}>
        <Grid item xs={12} md={4}>
          <Paragraph fontSize={{xs:"16px",md:"30px"}} padding={{xs:"0",md:"0 30px"}}>
            Q4 2024 
          </Paragraph>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph fontSize={{xs:"16px",md:"30px"}} padding={{xs:"0",md:"0 30px"}}>
            Q1 2025 
          </Paragraph>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paragraph fontSize={{xs:"16px",md:"30px"}} padding={{xs:"0",md:"0 30px"}}>
            Q2 2025 
          </Paragraph>
        </Grid>
      </Grid>
      <Grid container item xs={0} md={12} sx={{display:{xs:"none",md:"flex"}}}>
        <Grid item xs={12} md={4} sx={{display:{xs:"none",md:"flex"}}}>
          <Image src={checkArrow} alt=''/>
        </Grid>
        <Grid item xs={12} md={4} sx={{display:{xs:"none",md:"flex"}}}>
          <Image src={uncheckArrow} alt=''/>
        </Grid>
        <Grid item xs={12} md={4} sx={{display:{xs:"none",md:"flex"}}}>
          <Image src={uncheckArrow} alt=''/>
        </Grid>
      </Grid>
      <Grid container item xs={11} md={12} sx={{display:"flex"}}>
        <Grid item xs={12} md={4} paddingLeft="30px" margin={{xs:"0px 0 0",md:"0"}}>
          <DetailCard list={list1}/>
        </Grid>
        <Grid item xs={12} md={4} paddingLeft="30px" margin={{xs:"50px 0 0",md:"0"}}>
          <DetailCard list={list2}/>
        </Grid>
        <Grid item xs={12} md={4} paddingLeft="30px" margin={{xs:"50px 0 0",md:"0"}}>
          <DetailCard list={list3}/>
        </Grid>
      </Grid>
    </Grid>
    </Container>
    </MainHeader>
  )
}

export default Roadmap;