import { Container } from "@mui/material";
import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import BlueButton from "../BlueButton";
import Marquee from "react-fast-marquee";
import Paragraph from "../Paragraph";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Whitepaper",
      link: "https://blockinn.gitbook.io/blockinn",
    },
    {
      title: "Roadmap",
      link: "#roadmap",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "FAQ",
      link: "#faq",
    },
  
    {
      title: <BlueButton c="#fff">Buy $INN</BlueButton>,
      link: "https://app.uniswap.org/swap?outputCurrency=0x73B62d93928CE20392dD96Df5061c41E734BcDA5&chain=ethereum",
      p:"0 20px 0 5px",
      target:"blank"
    },
  ];
  return (
    <Container sx={{position:"fixed",top:"0",left:"50%",transform:"translate(-50%, 0)",background:"rgba(12, 20, 32,.57)",backdropFilter:"blur(30px)",zIndex:"999999999"}} maxWidth="xl" >
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
