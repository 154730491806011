import React from 'react'
import styled from '@emotion/styled'
import { Box, Container, Grid, Typography } from '@mui/material'
import RingAi from '../../../../assets/Images/Ring AI.png'
import PalMAi from '../../../../assets/Images/PaLM AI.png'
import AstraAi from '../../../../assets/Images/Astra AI.png'
import Shadownode from '../../../../assets/Images/Shadow Node.png'
import XAlpha from '../../../../assets/Images/XAlpha.png'
import Defenderbot from '../../../../assets/Images/Defender Bot.png'
import AGIX from '../../../../assets/Images/AGIX.png'
import ScottytheAi from '../../../../assets/Images/ScottytheAI.png'
import SectBot from '../../../../assets/Images/Sect Bot.png'
import CryptoAutos from '../../../../assets/Images/Crypto Autos.png'
import RingAi1 from '../../../../assets/Images/Ring AI-1.png'
import Phenx from '../../../../assets/Images/PhenX.png'
import EleveX from '../../../../assets/Images/EleveX.png'
import Messier from '../../../../assets/Images/Messier.png'
import Cryptify from '../../../../assets/Images/Cryptify.png'
import Regtxt from '../../Regtxt/index'
const Wrapper = styled(Box)`
   padding: 110px 0px;
`

const HeadingTypography = styled(Typography)`
    color: #ffffff;
    font-family: MontserratM;
    margin-bottom: 7%;
    @media (max-width:600px) {
       font-size: 35px;
       
    }

`
const HeadingparaTypography = styled(Typography)`
    color: #ffffff;
    font-family: MontserratM;
    @media (max-width:600px) {
       font-size: 21px;
       color: #ffffff;
       
    }
`

const Partimg = styled('img')`
    width: 100%;
    max-width: 100px;
`

const Partners = () => {
  return (
    <Wrapper id='our-partners'>
        <Container maxWidth='xl'>
            <Grid container textAlign={'center'} justifyContent={'center'}>
                <Grid item xs={12} >
                    <HeadingTypography variant='h2'>Our Partners</HeadingTypography>
                </Grid>


                <Grid container item justifyContent="center" spacing={4} marginBottom={'3%'}>
                    <Grid item>
                        <Partimg src={RingAi} alt="Ring AI"/>
                        <HeadingparaTypography>Damac</HeadingparaTypography>
                    </Grid>
                    <Grid item>
                        <Partimg src={PalMAi} alt="PalmAi"/>
                        <HeadingparaTypography>PaLM Ai</HeadingparaTypography>
                    </Grid>
                    <Grid item>
                        <Partimg src={AstraAi} alt="AstraAi"/>
                        <HeadingparaTypography>Astra Ai</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={Shadownode} alt="Shadownode"/>
                        <HeadingparaTypography>Shadow<br></br>Node</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={XAlpha} alt="XAlpha"/>
                        <HeadingparaTypography>XAlpha</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={Defenderbot} alt="Defenderbot"/>
                        <HeadingparaTypography>Defender <br></br>Bot</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={AGIX} alt="AGIX"/>
                        <HeadingparaTypography>AGIX</HeadingparaTypography>
                    </Grid>
                
                </Grid>
                <Grid container item justifyContent="center" spacing={4}>
                <Grid item>
                        <Partimg src={ScottytheAi} alt="Scottytheai"/>
                        <HeadingparaTypography>Scotty The<br/>AI</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={SectBot} alt="Sectbot"/>
                        <HeadingparaTypography>SECT BOT</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={CryptoAutos} alt="Crypto"/>
                        <HeadingparaTypography>Crypto <br/>Autos</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={RingAi1} alt="Ring"/>
                        <HeadingparaTypography>Ring AI</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={Phenx} alt="Phenx"/>
                        <HeadingparaTypography>PhenX</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={EleveX} alt="Elevex"/>
                        <HeadingparaTypography>EleveX</HeadingparaTypography>
                    </Grid>

                    <Grid item>
                        <Partimg src={Cryptify} alt="Cripty"/>
                        <HeadingparaTypography>Cryptify</HeadingparaTypography>
                    </Grid>
                    <Grid item>
                        <Partimg src={Messier} alt="Messier"/>
                        <HeadingparaTypography>Messier</HeadingparaTypography>
                    </Grid>


                    
                </Grid>



            </Grid>
        </Container>
    </Wrapper>
  )
}

export default Partners
