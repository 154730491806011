import { Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const StyledPara = styled(Typography)`
    @media(max-width: 508px){
         font-size: 10px;
    }
`

const Paragraph = (props) => {
    const {children}=props;
    return (
        <StyledPara variant="body1" color="white" fontFamily="MontserratR" textAlign="left"  {...props}>
            {children}
        </StyledPara>
    );
}
 
export default Paragraph;