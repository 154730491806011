import React from 'react'; 
import { Box, Container, Grid } from '@mui/material';
import Paragraph from '../../Paragraph'
import comunityImage from '../../../../assets/Images/comunityImage.png';  
import styled from '@emotion/styled';

import x from "../../../../assets/Images/x.png";
import tg from "../../../../assets/Images/tg.png";
import { MainIconImg2, WhiteLink } from '../S1-Home';
export const HeaderGrid = styled(Grid)`
  min-height: auto;
  background-image: url(${comunityImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  border-radius: 10px;
  padding: 180px 40px;
  width: 100%;

  @media(max-width: 600px){
    padding: 400px 20px;
  }
` 
const Coumunity = () => { 

  return (<Box position="relative" >
    <Container maxWidth="xl">
      <HeaderGrid container display="flex" justifyContent='start' alignItems="center">   
        <Grid item xs={12} md={5.9} lg={5.9} display="flex" flexDirection="column" padding="20px 0" gap="20px" data-aos="zoom-in" >    
          <Paragraph variant="h4" fontFamily="MontserratR" >Meet our native token</Paragraph>
          <Paragraph variant="h3" fontFamily="MontserratR" >Join Block Inn 
          Community</Paragraph>
          <Paragraph variant="body1" maxWidth="550px"> 
          Stay up to date with latest news & updates          </Paragraph> 
          <Paragraph variant="body1" maxWidth="550px"> 
          info@blockinn.estate         </Paragraph> 
          <Box display="flex" justifyContent="start" padding="10px 0" alignItems="center" width="100%" gap="10px">  
                <WhiteLink href="https://x.com/blockinnETH" target="blank">
                  <MainIconImg2  w="45px" src={x} alt="Twitter" />
                </WhiteLink>
                <WhiteLink href="https://t.me/blockinnETH" target="blank">
                  <MainIconImg2  w="45px" src={tg} alt="Telegram" />
                </WhiteLink>
              </Box>
        </Grid> 
       </HeaderGrid>
      </Container>
    </Box>
  )
}

export default Coumunity;