import React, { useEffect } from 'react'
import Home from '../../Components/BrickBlock_Section/S1-Home';
import About from '../../Components/BrickBlock_Section/S2-About';

import Coumunity from '../../Components/BrickBlock_Section/S4-Coumunity';
import Tokenomics from '../../Components/BrickBlock_Section/S5-Tokenomics';
import Summary from '../../Components/BrickBlock_Section/S7-Summary';
import End from '../../Components/BrickBlock_Section/S8-End';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Aos from 'aos';
import { Box } from '@mui/material';
import OurMission from '../../Components/BrickBlock_Section/S2-OurMission';
import Faq from '../../Components/BrickBlock_Section/S6-Faq';
import Roadmap from '../../Components/BrickBlock_Section/S6-Feature';
import Partners from '../../Components/BrickBlock_Section/S9-Partners';
// ..
const Main = () => { 
useEffect(()=>{

  Aos.init({
    duration:800,
  });
})

  return (<>
    <Box position="relative" zIndex="0" width="100%" height="100vh" sx={{position:"fixed",top:"0",background:"radial-gradient(ellipse at top, #fff -50%, #ff414180 10%, #00000085 70%)"}}>     
    </Box>
    <Box position="relative" zIndex="1">
      <Home /> 
      <OurMission/>
      <About />   
      <Partners />
      <Roadmap />
      <Tokenomics />
      <Faq/>
      <Coumunity /> 
      <Summary/>
      <End/>
    </Box>
  </>    
  )
}

export default Main;