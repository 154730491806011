import React from 'react'; 
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Mediumtxt from '../../../../Mediumtxt';
import Paragraph from '../../../../Paragraph';
import { Img } from '../../../../../Styles/Maintext.styles';
import card_bg from "../../../../../../assets/Images/card_bg.png"
import BlueButton from '../../../../BlueButton';



const AboutCard = (props) => {
      
  const BoxLay = styled(Box)`
    max-width: 100%; 
    min-height: ${props => props.h? props.h : '350px'}; 
    display:flex; 
    flex-direction: column; 
    justify-content: start; 
    align-items: center; 
    background-color:#0C1420;  
    background-image: ${ props => props.bg === true && `url(${card_bg})`};  
    background-repeat:no-repeat;
    background-position:top;
    background-size:contain;
    padding: 30px  40px; 
    gap: 20px; 
    border-radius: 7px;
    position: relative;
    transition: all .5s;
    z-index: 0;
   ` 
  const p =  props.padding ?  props.padding : '0px';
  return ( 
     <BoxLay bg={props.bg} padding={props.cardpading} h="450px"  data-aos="fade-up" marginTop={"100px"}> 
         <Img w={props.imgw? props.imgw:"200px"}  src={props.psrc} alt="pic" style={{padding:`${p}`}} />
         <Mediumtxt variant='h4' fontWeight="400" color="white" textAlign="center" sx={{maxWidth:{xs:"100%",sm:"70%"}}}>   
            {props.def1}
         </Mediumtxt>   
         <Paragraph variant="body1" lineHeight="1.5" fontWeight='400' textAlign="center" maxWidth='100%' fontFamily="MontserratR" paddingBottom="30px"> 
             {props.def2} 
        </Paragraph>
        <BlueButton c="#ffff" disabled href={props.href}>Coming Soon</BlueButton>
     </BoxLay>
  )
}
export default AboutCard;